function loadStripo(template, externalLib, emailId, userId) {
    initPlugin(template, externalLib, emailId, userId);
}

function exportHtml() {
    compileMail();
}

// Utility methods
function request(method, url, data, callback) {
    var req = new XMLHttpRequest();
    req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
            callback(req.responseText);
        } else if (req.readyState === 4 && req.status !== 200) {
            console.error('Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values');
        }
    };
    req.open(method, url, true);
    if (method !== 'GET') {
        req.setRequestHeader('content-type', 'application/json');
    }
    req.send(data);
}

// Call this function to start plugin.
// For security reasons it is STRONGLY recommended NOT to store your PLUGIN_ID and SECRET_KEY on client side.
// Please use backend middleware to authenticate plugin.
// See documentation: https://stripo.email/plugin-api/
function initPlugin(template, externalLib, emailId, userId) {
    const apiRequestData = {
        emailId: emailId,
        // UserId: 'marc'
        UserId: userId
    };
    const script = document.createElement('script');
    script.id = 'stripoScript';
    script.type = 'text/javascript';
    script.src = 'https://plugins.stripo.email/static/stripo.js';
    script.onload = function () {
        window.Stripo.init({
            settingsId: 'stripoSettingsContainer',
            previewId: 'stripoPreviewContainer',
            codeEditorButtonId: 'codeEditor',
            locale: 'fr',
            html: template.html,
            css: template.css,
            externalSmartElementsLibrary: externalLib,
            enableWebSiteLinksForSmartElementsLibrary : true,
            ignoreClickOutsideSelectors: '#externalSmartElementsLibrary',
            apiRequestData: apiRequestData,
            undoButtonId: 'undoButton',
            redoButtonId: 'redoButton',
            getAuthToken: function (callback) {
                request('POST', 'https://plugins.stripo.email/api/v1/auth',
                    JSON.stringify({
                        pluginId: '731f5f4bbab542e9ac22a1537b52ac9d',
                        secretKey: '50448b1311ab40988cd38f45b25ffada'
                    }),
                    function(data) {
                        callback(JSON.parse(data).token);
                    });
            }
        });
    };
    document.body.appendChild(script);
}

function getHtmlCss(func) {
    window.StripoApi.getTemplate(function(html, css) {
        window.htmlcss = {html: html, css: css};
        func.call();
    });
}

function compileMail(func) {
    window.StripoApi.compileEmail(function(error, html, ampHtml, ampErrors) {
        window.htmlcss = {html: html};
        func.call();
    }, false);
}
